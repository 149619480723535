import React, { useState } from 'react'
import { IconLoginWrapper, MobileInfoUser, MobileNav } from './style'
import toggleIcon from 'assets/images/icon/toggle-menu.png'
import LoginIcon from 'assets/images/icon/Login_Default.png'
import logo from 'assets/images/login/logo.png'
import userIcon from 'assets/images/icon/user.png'
import CustomButton from 'components/CustomButton'
import { useDispatch, useSelector } from 'react-redux'
import { isEmptyObject } from 'utils/ref'
import { Link, useHistory } from 'react-router-dom'
import { UrlInternal } from 'common/constants/endpoints'
import FloatMenu from './FloatMenu'
import types from 'store/actions/types'
import moneyConvert from 'utils/moneyConvert'
import { FiLogIn } from 'react-icons/fi'
const MobileHeader = () => {
  const user = useSelector((s: any) => s.user)
  const [toggle, setToggle] = useState(false)
  const dispatch = useDispatch()

  const openLogin = () => {
    dispatch({ type: types.OPEN_MODAL, payload: { name: 'login' } })
  }

  return (
    <>
      {!isEmptyObject(user.dataUser) && (
        <MobileInfoUser>
          <div className='item'>
            <div className='title'>보유머니*</div>
            <div className='value'>
              {moneyConvert(user?.dataUser?.mb_money)} 원
            </div>
          </div>
          <div className='item'>
            <div className='title'>포인트*</div>
            <div className='value'>
              {moneyConvert(user?.dataUser?.mb_point)} 점
            </div>
          </div>
        </MobileInfoUser>
      )}
      <MobileNav>
        <div className='toggle' onClick={() => setToggle(true)}>
          <img src={toggleIcon} alt='' />
        </div>
        <Link to={UrlInternal.HOME} className='logo'>
          <img src={logo} alt='' />
        </Link>
        {isEmptyObject(user.dataUser) ? (
          <CustomButton onClick={openLogin}>
              로그인
          </CustomButton>
        ) : (
          <Link className='user' to={UrlInternal.USER}>
            <img src={userIcon} alt='' />
          </Link>
        )}
      </MobileNav>
      <FloatMenu
        hideLogout={isEmptyObject(user.dataUser)}
        show={toggle}
        onClose={() => setToggle(false)}
      />

    </>
  )
}

export default MobileHeader
