/* eslint-disable prettier/prettier */
import React from "react"
import AuthLayout from "./AuthLayout"
import { AuthWrapper } from "../style"

const SameRef = () =>{
    return (
        <AuthLayout>
            <AuthWrapper>
            <div className='title'>통보</div>
            <div className="title" style={{fontSize:"25px"}}>
                이 계정은 이미 추천되었습니다. 더 이상 추천을 할 수 없습니다.
            </div>
            </AuthWrapper>
        </AuthLayout>
    )
}
export default SameRef