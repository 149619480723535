import React from "react";
import { Button } from "./style";

type Props = {
  children: string
  onClick?: () => void;
  type?: any,
  disabled?: boolean;
  width?: number
}

const CustomButton:React.FC<Props> = ({children, onClick, type, disabled, width}) =>{
  return (
    <Button disabled={disabled} htmlType={type} type={type} onClick={onClick} style={{width:`${width}px`}}>
      <span>{children}</span>
    </Button>
  )
}

export default CustomButton
