import styled, { css } from 'styled-components'
import theme from 'common/style/styleSetup'
import btnGold from 'assets/images/button/btn-gold.png';
import btnGoldActive from 'assets/images/button/btn-gold-active.png';
export const Header = styled.div`
  // background-color: #050511;
  background-color: ${theme.bg[193]};
  display: flex;
  flex-direction: column;
  justify-content: center;
  position: relative;
  z-index: 1;
  @media screen and (max-width: 768px) {
    display: none;
  }
  /* body .navbar-overlay { */
  /* @media screen and (max-width: 768px) {
    background: rgba(0, 0, 0, 0.5);
    position: fixed;
    z-index: 2;
    top: 0;
    left: 0; */
  /* width: 100%;
      height: 100%; */
  /* width: 100vw;
    height: 100vh; */
  /* visibility: visible; */
  /* opacity: 1; */
  /* transition: 0.3s; */
  /* } */
  /* } */
`

export const Avatar = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
  p {
    margin: 0px;
  }
`
export const Row = styled.div`
  display: flex;
`
export const Menu = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
`
export const Info = styled.div`
  color: yellow;
  margin-left: 30px;
  cursor: pointer;
  img {
    width: 20px;
  }
  span {
    color: white;
  }
`
export const Content = styled.div`
  display: flex;
  height: 30px;
  align-items: center;
  filter: brightness(0.85);
  border: 3px groove yellow;
  border-radius: 10px;
  width: 100px;
  justify-content: center;
  p {
    margin: 0;
    font-weight: 300;
  }
  padding: 5px;
  margin: 2px;
  span {
    color: white !important;
  }
  :hover {
    filter: brightness(1.5);
  }

  ::before {
    content: '';
    position: absolute;
    width: 10px;
    height: 100%;
    background-color: olive;
    transform: skewX(-1deg);
    transform-origin: right center;
    opacity: 0;
    left: -1em;
    transition: opacity 0.3s ease-in-out;
    box-shadow: 0 0 0.4em olive;
  }
  :hover::before,
  :hover::after {
    opacity: 0.9; /* Set opacity to 0 to disable the shadow effect */
  }
  cursor: pointer;
  :hover p {
    font-size: 18px;
  }
`
export const ContentLogOut = styled.div`
  display: flex;
  height: 30px;
  width: 100px;
  justify-content: center;
  align-items: center;
  filter: brightness(0.85);
  border: 3px groove red;
  border-radius: 10px;
  p {
    margin: 0;
    font-weight: 300;
  }
  padding: 5px;
  margin: 2px;
  span {
    color: white !important;
  }
  :hover {
    filter: brightness(1.5);
  }

  ::before {
    content: '';
    position: absolute;
    width: 10px;
    height: 100%;
    background-color: #800000;
    transform: skewX(-1deg);
    transform-origin: right center;
    opacity: 0;
    left: -1em;
    transition: opacity 0.3s ease-in-out;
    box-shadow: 0 0 0.4em #800000;
  }
  :hover::before,
  :hover::after {
    opacity: 0.9; /* Set opacity to 0 to disable the shadow effect */
  }
  cursor: pointer;
  :hover p {
    font-size: 18px;
  }
`
export const AvatarImg = styled.div`
  text-algin: center;
  background: white;
  width: 45px;
  height: 45px;
  border-radius: 50px;
  justify-content: center;
  display: flex;
  margin: 0px;
  img {
    width: 35px;
    height: 35px;
    margin-top: 3px;
    cursor: pointer;
  }
`
export const Wrapper = styled.div`
  width: 100%;
  border-top: solid 1px 1px ${theme.bg[9]};
  border-bottom: solid 1px 1px ${theme.bg[9]};
  background-image: linear-gradient(#23344a, #13253e);
  box-shadow: inset 0 3px 0 #0e151e;
`

export const WrapperAuth = styled.div`
  position: relative;
  z-index: 10;
  max-width: 90vw;
  margin: auto;
  padding: 15px 2%;
  display: flex;
  justify-content: flex-end;
  gap: 5px;
  align-items: center;
  padding-right: 2%;
  .btn {
    cursor: pointer;
    width: 100%;
    max-width: 150px;
    img {
      width: 100%;
    }
  }
  @media screen and (max-width: 768px) {
    display: none;
  }
`

export const WrapperTop = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  margin: 0 auto;
  z-index: 100;
  max-width: 1270px;
  height: 90px;
  @media screen and (max-width: 1250px) {
    width: 100%;
    justify-content: center;
    flex-direction: column;
    padding: 0px;
    margin: 0 0 0 0;
    gap: 0px;
  }
  @media screen and (max-width: 768px) {
    height: 120px;
    flex-direction: row;
  }
  position: static;
`
export const Logo = styled.div`
  position: absolute;
  cursor: pointer;
  width: 100%;
  img{
    max-width: 180px;
  }
`
export const MenuWraper = styled.div`
  /* padding: 12px 12px;
  background-color: white;
  border: 4px solid green; */
  @media screen and (min-width: 768px) {
    display: none;
  }
`
export const MenuGroupList = styled.div<{ show?: boolean | undefined }>`
  /* padding: 12px 12px;
  background-color: white;
  border: 4px solid green; */
  /* @media screen and (min-width: 768px) {
    display: none;
  } */
  ${(props) =>
    props.show &&
    css`
      @media screen and (max-width: 768px) {
        background: rgba(0, 0, 0, 0.5);
        position: fixed;
        z-index: 4;
        top: 0;
        left: 0;
        width: 100vw;
        height: 100vh;
        visibility: visible;
        opacity: 1;
        transition: 0.3s;
      }
    `}
`

export const TopLogo = styled.div`
  width: 100%;
  max-width: 220px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  padding: 0 10px;
  @media screen and (max-width: 1024px) {
    max-width: 150px;
  }
  img {
    width: 100%;
    animation: logoAkAnim 8s ease infinite;
    height: auto;
  }
`
export const TopButton = styled.div<{ show?: boolean | undefined }>`
  display: flex;
  width: 100%;
  height: 100%;
  justify-content: center;
  .content-top {
    position: relative;
    font-weight: 600;
    color: ${theme.color[0]};
    justify-content: center;
    display: flex;
    align-items: center;
    /* margin: 0 auto; */
    font-size: 12px;
    transition: all 0.3s ease-in-out;
    width: 100%;
    max-width: 190px;

    .img-box {
      justify-content: center;
      width: 100%;
      display: flex;
      align-items: center;
      // gap: 15px;
      &.event {
        .img {
          max-width: 40px;
          img {
            width: 100%;
          }
        }
      }
      span {
        // background: var(
        //   --Gold-stroke,
        //   linear-gradient(180deg, #feeac3 0%, #ffe6a6 29.17%, #ffb800 100%)
        // );
        color: #FECC7A;
        background-clip: text;
        -webkit-background-clip: text;
        // -webkit-text-fill-color: transparent;
        font-size: 1.7em;
        font-style: 100%;
        font-weight: 500;
        @media screen and (max-width: 1024px) {
          font-size: 1.2em;
        }
      }
      .div-img{
        max-width: 65px;
        .img-hover {
          display: none;
          min-width: 65px;
        }
        .img-normal {
          width: 100%;
        }
      }
      img {
        // max-width: 45px;
        @media screen and (max-width: 1024px) {
          width: 30px;
        }
      }
    }
    .active-img {
      display: none;
    }
    &.large-icon {
      img {
        margin-top: 10px;
      }
    }
    &.logout {
      background: ${theme.bg[11]};
      border: solid 1px ${theme.bg[14]};
      border: none;
    }
    &::before {
      content: '';
      position: absolute;
      width: 100%;
      height: 100%;
      left: 0;
      top: 0;
      // border: 1px solid #00EBE7;
      border-right: 2px solid var(--Gold-stroke, #00EBE7);
      border-left: 2px solid var(--Gold-stroke, #00EBE7);
      z-index: 1;
      display: none;
    }
    :hover,
    &.active {
      &::before {
        display: block;
      }
      .img-box {
        .img-normal {
          display: none;
        }
        .img-hover {
          display: block;
          width: 45px;
        }
        span {
          text-shadow: 2px 2px 4px #000000;
        }
      }
      background: var(
        --Gold-Button,
        linear-gradient(180deg, #11172C 0%, #00EBE7 100%)
      );
      cursor: pointer;
      &.logout {
        background: ${theme.bg[12]};
      }
    }
    .fa {
      margin: -3px 4px 0 0;
    }
  }
  @media screen and (max-width: 1250px) {
    // display: none;
  }
  @media screen and (max-width: 768px) {
    display: none;
    ${(props) =>
      props.show &&
      css`
        display: flex;
        position: fixed;
        flex-direction: column;
        justify-content: flex-start;
        row-gap: 24px;
        align-items: flex-start;
        top: 0;
        padding: 24px;
        left: 1px;
        width: max-content;
        height: 100%;
        z-index: 5;
        background-color: black;
      `}
  }
`

export const WrapperBot = styled.div`
  max-width: 1350px;
  width: 100%;
  height: 46px;
  justify-content: space-between;
  display: flex;
  align-items: center;
  margin: 0 auto;
  .item {
    color: ${theme.color[7]};
    width: 10%;
    height: 24px;
    padding: 0 12px;
    text-align: center;
    border-left: 1px solid ${theme.bg[15]};
    justify-content: center;
    display: flex;
    align-items: center;
    :nth-child(10) {
      border-right: 1px solid ${theme.bg[15]};
    }
    .content {
      width: 100%;
      height: 28px;
      padding-top: 1px;
      line-height: 28px;
      border-radius: 4px;
      cursor: pointer;
      font-size: 14px;
      :hover {
        background: ${theme.bg[16]};
        border: solid 1px ${theme.bg[17]};
      }
    }
  }
`

export const UserTag = styled.div`
  display: flex;
  min-width: 150px;
  height: 45px;
  align-items: center;
  justify-content: center;
  background-image: url(${btnGold});
  gap: 9px;
  padding: 8px 20px;
  cursor: pointer;
  position: relative;
  background-repeat: no-repeat;
  background-size: 100% 100%;
  span {
    color: white;
    background-clip: text;
    -webkit-background-clip: text;
    font-size: 18px;
    font-style: normal;
    font-weight: 700;
  }
  .icon {
    width: 25px;
    .icon-nor {
      width: 30px;
    }
    .icon-hover{
      width: 22px;
      display: none;
    }
  }
  &:hover {
    border-color: transparent !important;
    background-image: url(${btnGoldActive});
    span {
      color: #FECC7A;
    }
    .icon-hover{
      display: block;
    }
    .icon-nor {
      display: none;
    }
  }
`

export const InfoUser = styled.div`
  display: flex;
  .cash-info {
    background: #103A4A80;
    font-size: 20px;
    font-weight: 500;
    line-height: normal;
    padding: 14px;
    z-index: 100;
    min-width: 300px;
    .item {
      display: flex;
      justify-content: space-between;
      .title {
        color: #fff;
        width: 33%;
      }
      .value {
        background: var(
          --Button-hover,
          linear-gradient(180deg, #F8504C 0%, #FECC7A 100%)        );
        background-clip: text;
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
        font-weight: 700;
      }
    }
  }
`

export const BtnMenu = styled.div`
  cursor: pointer;
  margin-left: 24px;
`

export const BoxItem = styled.div`
  height: 100%;
`
export const BoxItemP = styled.p``
