import React from 'react' //lazy
import foooter1 from 'assets/images/button/footer1.png'
import foooter2 from 'assets/images/button/footer2.png'
import foooter3 from 'assets/images/button/footer3.png'
import foooter1Hover from 'assets/images/button/footer1Hover.png'
import foooter2Hover from 'assets/images/button/footer2Hover.png'
import foooter3Hover from 'assets/images/button/footer3Hover.png'
import logo from 'assets/images/login/logo.png'
import { useHistory } from 'react-router-dom'
import { UrlInternal } from 'common/constants/endpoints'
import { Layout } from 'antd'
// import { ArrowUpOutlined } from "@ant-design/icons";

// import { UrlInternal } from "common/constants/endpoints";

import * as S from './style'

const { Footer } = Layout

interface Props {
  onClick?: any
  className?: any
}
const data = [
  {
    image: foooter1,
    imageHover: foooter1Hover,
    link: UrlInternal.PAYMENT
  },
  {
    image: foooter2,
    imageHover: foooter2Hover,
    link: UrlInternal.DEPOSIT
  },
  {
    image: foooter3,
    imageHover: foooter3Hover,
    link: UrlInternal.MESSAGE
  }
]
const data1 = [
  '아리스트크랏',
  '노보메틱',
  '그린튜브',
  'NYX/넥스트젠',
  '넷엔트',
  'EGT',
  'Igrosoft',
  'APLSGAMES',
  'megaJack',
  'UNICUM',
  'BABOCHKI'
]
const FooterPage = (props: Props) => {
  const { onClick, className } = props
  const history = useHistory()
  const handleClick = (link: any) => {
    history.push(link)
  }
  return (
    <S.Footers>
      <div className='left'>
        <p className='left-title'>파트너 제휴 문의</p>
        <div className='left-box'>
          {data &&
            data.map((item: any, index: number) => {
              return (
                <button
                  className='left-button'
                  key={index}
                  onClick={() => handleClick(item.link)}
                >
                  <div className='img-box' >
                    <div className='div-img'>
                      <img
                        className='img-normal'
                        alt=''
                        src={item.image}
                      />
                      <img
                        className='img-hover'
                        alt=''
                        src={item.imageHover}
                      />
                    </div>
                  </div>

                </button>
              )
            })}
        </div>
      </div>
      <div className='middle'>
        <img alt='logo' src={logo} />
        Copyright {new Date().getFullYear()} All Rights Reserved.
      </div>
      <div className='right'>
        <p className='right-title'>QUICK SERVICE</p>
        <div className='right-box'>
          {data1.map((i, index: number) => {
            return <span key={index}>{i}</span>
          })}
        </div>
      </div>
    </S.Footers>
  )
}

export default FooterPage
