/**
 * @author [Peter]
 * @email [hoangvanlam9988@mail.com]
 * @create date 2022-02-19 15:25:35
 * @modify date 2022-02-19 15:25:35
 * @desc [description]
 */

import React, { useEffect, useLayoutEffect, useState } from 'react'
import { UrlInternal } from 'common/constants/endpoints'
import { useDispatch, useSelector } from 'react-redux'
import { Redirect, Route, useHistory, useLocation, useParams } from 'react-router-dom'
import { getLocalStorage, saveLocalStorage } from 'utils/localStorage'
import { isEmptyObject } from 'utils/ref'
import types from 'store/actions/types'
import { loginApi, meApi } from 'apis'
import { getTokenTeleLogin } from 'apis/auth'
export function PublicRoute({
  component: TargetPage,
  ...rest
}: any): React.ReactElement {
  const history = useHistory()
  const userData = useSelector((state: any) => state.user)
  const dispatch = useDispatch()
  const pathName = useLocation()

  async function checkTeleAccount(query: string) {
    const queryString = query
    const params: any = new URLSearchParams(queryString)
    const paramsObject: any = {};
    for (const [key, value] of params.entries()) {
       paramsObject[key] = value
    }
    
    const bearerRes: any = await getTokenTeleLogin({
      id: paramsObject.id,
      username: paramsObject.username,
      mb_rec_id: paramsObject.mb_rec_id
    })
    if(bearerRes.result){
      history.push(UrlInternal.HOME)
      saveLocalStorage({
        name: 'User',
        data: bearerRes?.access_token
      })
      const resMe: any = await meApi()
        if (resMe.result === false) {
          alert(resMe.message)
          dispatch({ type: 'STOP_WAITTING' })
        } else {
          dispatch({ type: 'STOP_WAITTING' })
          dispatch({
            type: types.GET_DATA_USER,
            dataUser: resMe.data
          })
          // history.push(UrlInternal.HOME)
        }
    }
    else{
      dispatch({ type: types.OPEN_MODAL, payload: { name: 'sameRef' } })
    }
          // console.log(res);
          // if (res.result) {
          //   saveLocalStorage({
          //     name: 'User',
          //     data: res?.access_token
          //   })
          //   if (resMe.result === false) {
          //     alert(resMe.message)
          //     dispatch({ type: 'STOP_WAITTING' })
          //   } else {
          //     dispatch({ type: 'STOP_WAITTING' })
          //     dispatch({
          //       type: types.GET_DATA_USER,
          //       dataUser: resMe.data
          //     })
          //   }
          //   history.push(UrlInternal.HOME)
          //   dispatch({ type: 'STOP_WAITTING' })
          // } else {
          //   alert(res?.error.message)
          //   dispatch({ type: 'STOP_WAITTING' })
          // }

          // history.push(UrlInternal.HOME)
    dispatch({ type: 'STOP_WAITTING' })
  }
  useEffect(() => {
    if (pathName.search.includes('tele')) {
      checkTeleAccount(pathName?.search)
    }
  }, [])
  useLayoutEffect(() => {
    const user = localStorage.getItem('User')
    getLocalStorage({ typeGetData: 'User' }).then((v: any) => {
      if (isEmptyObject(userData.dataUser) && !user && !pathName.search.includes('tele')) {
        history.push({
          pathname: UrlInternal.HOME,
          state: { fromURL: history.location.pathname }
        })
        dispatch({ type: types.OPEN_MODAL, payload: { name: 'login' } })
      }
    })
  }, [history.location.pathname])

  return <Route {...rest} render={(props: any) => <TargetPage {...props} />} />
}

export function PrivateRoute({
  component: TargetPage,
  isAuthenticated = true,
  ...rest
}: any): React.ReactElement {
  const history = useHistory()
  const userData = useSelector((state: any) => state.user)

  useEffect(() => {
    getLocalStorage({ typeGetData: 'User' }).then((v: any) => {
      if (!userData && !v) {
        history.push({
          pathname: UrlInternal.LOGIN,
          state: { fromURL: history.location.pathname }
        })
      }
    })
  }, [])

  return (
    <Route
      {...rest}
      render={(props: any) =>
        userData || isAuthenticated ? (
          <TargetPage {...props} />
        ) : (
          <Redirect
            to={{
              pathname: UrlInternal.LOGIN,
              state: { fromURL: props?.location?.pathname }
            }}
          />
        )
      }
    />
  )
}
