import styled from 'styled-components'
import btnGold from 'assets/images/button/Button2.png';
import btnGoldActive from 'assets/images/button/Button2-hover.png';

import { Button as AntButton } from 'antd'
export const Button = styled(AntButton)`
  background-image: url(${btnGold});
  color: #000;
  width: 150px;
  height: 45px;
  background-color: transparent;
  background-size: 100% 100%;
  border-color: transparent;
  background-position: center;
  cursor: pointer;
  background-repeat: no-repeat;
  div{
    display: none;
  }
  &:hover{
    border-color: transparent !important;
    background-image: url(${btnGoldActive});
    span{
      color: white
      
    }
  }
  &:focus{
    border-color: transparent;
  }
  &:disabled{
    cursor: not-allowed;
    filter: grayscale(1);
  }
  span{
    color: black;
    text-align: center;
    font-size: 18px;
    font-style: normal;
    font-weight: 700;
    line-height: 100%;
    display: block;
  }
  @media screen and (max-width: 768px) {
    width: 90px;
    height: 25px;
    span{
      font-size: 12px;
      margin-top: 2px;
    }
  }
`
