/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable no-undef */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React, { useEffect, useState } from 'react' //lazy
import { useHistory } from 'react-router-dom'
import { useSelector, useDispatch } from 'react-redux'
import btnAddValue from 'assets/images/button/btn-addvalue.png'
import btnAddValueHover from 'assets/images/button/btn-addvalue-hover.png'
import btnWithDraw from 'assets/images/button/btn-withdraw.png'
import btnWithDrawHover from 'assets/images/button/btn-withdraw-hover.png'
import btnNoti from 'assets/images/button/btn-noti.png'
import btnNotiHover from 'assets/images/button/btn-noti-hover.png'
import btnMess from 'assets/images/button/btn-adminmess.png'
import btnMessHover from 'assets/images/button/btn-adminmess-hover.png'
import btnUser from 'assets/images/button/Button-2.png'
import btnAddValueBlack from 'assets/images/button/btn-addvalue-black.png'
import btnWithDrawBlack from 'assets/images/button/btn-withdraw-black.png'
import btnNotiBlack from 'assets/images/button/btn-noti-black.png'
import btnMessBlack from 'assets/images/button/btn-adminmess-black.png'
import btnUserBlack from 'assets/images/button/Button-2-black.png'
import btnUserBlackHover from 'assets/images/button/Button-2-black-hover.png'
import eventActive from 'assets/images/button/eventNoActive.png'
import eventActiveHover from 'assets/images/button/eventNoActive-hover.png'
import btnMenu from 'assets/images/button/ButtonMenu.png'
import userIcon from 'assets/images/user/user.png'
import userIconHover from 'assets/images/user/user-hover.png'
import { UrlInternal } from 'common/constants/endpoints'
import logo from 'assets/images/login/logo.png'
import { Popover } from 'antd'
// import logo from 'assets/images/login/World_Logo.png'
// import { Popover, Tooltip } from 'antd'
import './style.css'
import avatar from 'assets/images/avatar.png'
import * as S from './style'
import changePoint from 'assets/images/Icon.png'
import types from 'store/actions/types'
import CustomButton from 'components/CustomButton'
import { isEmptyObject } from 'utils/ref'
import MobileHeader from 'components/MobileHeader'
import moneyConvert from 'utils/moneyConvert'
import CustomButton2 from 'components/CustomButton2'
interface Props {
  onClick?: any
  className?: any
}

const HeaderPage = (props: Props) => {
  const history = useHistory()
  const { onClick, className } = props
  const dataMe = useSelector((state: any) => state.user)
  const [onShowMenu, setOnShowMenu] = useState<boolean>(false)
  const dispatch = useDispatch()

  const handleOpenModal = (name: string) => {
    dispatch({ type: types.OPEN_MODAL, payload: { name: name } })
  }
  const handleShow = () => {
    setOnShowMenu(!onShowMenu)
  }
  const Logout = () => {
    localStorage.removeItem('User')
    localStorage.removeItem('Popup')
    dispatch({
      type: types.GET_DATA_USER,
      dataUser: {}
    })
    history.push(UrlInternal.HOME)
  }

  const activeTab = (name: string) => {
    return history.location.pathname === name
  }


  return (
    <>
      <S.WrapperAuth>
        <S.Logo>
          <div onClick={() => history.push(UrlInternal.HOME)}>
            <img src={logo} alt='logo'/>
          </div>
        </S.Logo>

        {isEmptyObject(dataMe.dataUser) ? (
          <>
            <CustomButton onClick={() => handleOpenModal('login')}>
              로그인
            </CustomButton>
            <CustomButton onClick={() => handleOpenModal('register')}>
              회원가입
            </CustomButton>
          </>
        ) : (
          <>            
            <S.InfoUser>
              <div className='cash-info'>
                <div className='item'>
                  <div className='title'>보유머니*</div>
                  <div className='value'>
                    {moneyConvert(dataMe.dataUser.mb_money)} 원
                  </div>
                </div>
                <div className='item'>
                  <div className='title'>포인트*</div>
                  <div className='value'>
                    {moneyConvert(dataMe.dataUser.mb_point)} 점
                  </div>
                </div>
              </div>
            </S.InfoUser>
            <S.UserTag onClick={() => history.push(UrlInternal.USER)}>
              <div className='icon'>
                <img className='icon-nor' src={userIcon} alt='' />
                <img className='icon-hover' src={userIconHover} alt='' />
              </div>
              <span>{dataMe.dataUser.mb_name}</span>
            </S.UserTag>
            <CustomButton2 onClick={() => Logout()}>로그아웃</CustomButton2>
          </>
        )}

      </S.WrapperAuth>
      <S.Header className='navbar-overlay'>
        <S.WrapperTop>
          <S.MenuWraper>
            <S.BtnMenu onClick={handleShow}>
              <img alt='' src={btnMenu} />
            </S.BtnMenu>
          </S.MenuWraper>

          <S.MenuGroupList
            onClick={handleShow}
            show={onShowMenu}
          ></S.MenuGroupList>
          <S.TopButton show={onShowMenu}>
            <S.BoxItem
              className={
                activeTab(UrlInternal.DEPOSIT)
                  ? 'active content-top'
                  : 'content-top'
              }
              onClick={() => history.push(UrlInternal.DEPOSIT)}
            >
              <div className='img-box'>
                <div className='div-img'>
                  <img
                    className='img-normal'
                    style={{ marginTop: '-5px' }}
                    alt=''
                    src={btnAddValue}
                  />
                  <img
                    className='img-hover'
                    style={{ marginTop: '-5px' }}
                    alt=''
                    src={btnAddValueHover}
                  />
                </div>

                <span>입금</span>
              </div>

              <img className='active-img' alt='' src={btnAddValueBlack} />
            </S.BoxItem>

            <S.BoxItem
              className={
                activeTab(UrlInternal.PAYMENT)
                  ? 'active content-top large-icon'
                  : 'content-top large-icon'
              }
              onClick={() => history.push(UrlInternal.PAYMENT)}
            >
              <div className='img-box' style={{ gap: '5px' }}>
                <div className='div-img'>
                  <img
                    className='img-normal'
                    style={{ marginTop: '-5px', width: '75px' }}
                    alt=''
                    src={btnWithDraw}
                  />
                  <img
                    className='img-hover'
                    style={{ marginTop: '-5px', width: '75px' }}
                    alt=''
                    src={btnWithDrawHover}
                  />
                </div>
                <span>출금</span>
              </div>

              <img className='active-img' alt='' src={btnWithDrawBlack} />
            </S.BoxItem>
            <S.BoxItem
              className={
                activeTab(UrlInternal.MESSAGE)
                  ? 'active content-top'
                  : 'content-top'
              }
              onClick={() => history.push(UrlInternal.MESSAGE)}
            >
              <div className='img-box'>
                <div className='div-img'>
                  <img
                    className='img-normal'
                    style={{ marginTop: '-5px', width: '68px' }}
                    alt=''
                    src={btnMess}
                  />
                  <img
                    className='img-hover'
                    style={{ marginTop: '-5px', width: '68px' }}
                    alt=''
                    src={btnMessHover}
                  />
                </div>
                <span>쪽지</span>
              </div>
              <img className='active-img' alt='' src={btnMessBlack} />
            </S.BoxItem>
            <S.BoxItem
              className={
                activeTab(UrlInternal.NOTIFICATION)
                  ? 'active content-top'
                  : 'content-top'
              }
              onClick={() => history.push(UrlInternal.NOTIFICATION)}
            >
              <div className='img-box'>
                <div className='div-img'>
                  <img
                    className='img-normal'
                    style={{ marginTop: '-5px', width: '60px' }}
                    alt=''
                    src={btnNoti}
                  />
                  <img
                    className='img-hover'
                    style={{ marginTop: '-5px', minWidth: '60px' }}
                    alt=''
                    src={btnNotiHover}
                  />
                </div>
                <span>공지사항</span>
              </div>
              <img className='active-img' alt='' src={btnNotiBlack} />
            </S.BoxItem>
            <S.BoxItem
              className={
                activeTab(UrlInternal.EVENT)
                  ? 'active content-top'
                  : 'content-top'
              }
              onClick={() => history.push(UrlInternal.EVENT)}
            >
              <div className='img-box'>
                <div className='div-img'>
                  <img
                    className='img-normal'
                    style={{ marginTop: '-5px', width: '55px' }}
                    alt=''
                    src={eventActive}
                  />
                  <img
                    className='img-hover'
                    style={{ marginTop: '-5px', minWidth: '55px' }}
                    alt=''
                    src={eventActiveHover}
                  />
                </div>
                <span>이벤트</span>
              </div>
              <img className='active-img' alt='' src={eventActive} />
            </S.BoxItem>
            <div
              className={
                activeTab(UrlInternal.POINT)
                  ? 'active content-top'
                  : 'content-top'
              }
              onClick={() => history.push(UrlInternal.POINT)}
            >
              <div className='img-box'>
                <div className='div-img'>
                  <img
                    className='img-normal'
                    style={{ marginTop: '-5px', width: '65px' }}
                    alt=''
                    src={btnUserBlack}
                  />
                  <img
                    className='img-hover'
                    style={{ marginTop: '-5px' }}
                    alt=''
                    src={btnUserBlackHover}
                  />
                </div>
                <span>포인트전환</span>
              </div>

              <img className='active-img' alt='' src={btnUserBlack} />
            </div>
          </S.TopButton>
        </S.WrapperTop>
      </S.Header>
      <MobileHeader />
    </>
  )
}

export default HeaderPage
