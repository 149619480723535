import styled from 'styled-components'
export const MobileNav = styled.div`
  background: #103A4A;
  padding: 10px 15px;
  position: relative;
  z-index: 10;
  justify-content: space-between;
  align-items: center;
  .logo {
    max-width: 100px;
    width: 100%;
    img {
      width: 100%;
    }
  }
  display: none;
  @media screen and (max-width: 768px) {
    display: flex;
  }

  .user,
  .toggle {
    cursor: pointer;
    width: 35px;
    img{
      max-width: 35px;
    }
  }
`

export const IconLoginWrapper = styled.div`
  svg {
    font-size: 25px;
    background: var(
      --Gold-stroke,
      linear-gradient(180deg, #feeac3 0%, #ffe6a6 29.17%, #ffb800 100%)
    );
    background-clip: text;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
  }
`
export const MobileInfoUser = styled.div`
  // height: 20px;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 10px;
  padding: 10px;
  z-index: 10;
  position: relative;

  background: #11172C;
  .item {
    display: flex;
    justify-content: space-between;
    gap: 10px;
    .title {
      color: #fff;
      // width: 33%;
    }
    .value {
      background: var(
        --Button-hover,
        linear-gradient(180deg, #F8504C 0%, #FECC7A 100%)      );
      background-clip: text;
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
      font-weight: 700;
    }
  }

  @media screen and (min-width: 768px) {
    display: none;
  }
`

export const Menu = styled.div`
  position: absolute;
  z-index: 20;
  width: 55vw;
  height: 100vh;
  padding-top: 40px;
  top: 0;
  left: calc(-55vw - 45px);
  background: rgba(17, 23, 44, 0.7);
  backdrop-filter: blur(20px);
  transition: all 0.3s;
  &.show {
    left: 0;
  }
  .logout {
    position: absolute;
    bottom: 35px;
    left: calc(50% - 45px);
  }
  .close {
    position: absolute;
    top: 10px;
    right: -30px;
    img{
      max-width: 60px;
    }
  }
  .menu-item {
    width: 100%;
    height: 46px;
    display: flex;
    justifi-content: center;
    align-items: center;
    padding-left: 14px;
    .img-box {
      width: 100%;
      display: flex;
      align-items: flex-end;
      gap: 15px;
      span {
        background: white;
        background-clip: text;
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
        font-size: 15px;
        font-style: 100%;
        font-weight: 700;
      }
      img {
        max-width: 25px;
        width: 100%;
        object-fit: contain;
      }
    }
  }
`
