import React from "react";
import { Button } from "./style";

type Props = {
  children: string
  onClick?: () => void;
}

const CustomButton2:React.FC<Props> = ({children, onClick}) =>{
  return (
    <Button onClick={onClick}>
      <span>{children}</span>
    </Button>
  )
}

export default CustomButton2
